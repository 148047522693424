import "styles/pages/contact.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"
import ContactForm from "components/ContactForm/ContactForm"

import IcoPhone from "assets/images/ico-phone.svg"
import IcoEnvelope from "assets/images/ico-envelope.svg"

const Contact = () => {
  const title = "Kontakt"

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="contact">
        <div className="container-fluid">
          <PageHeader title={title} />

          <div className="row">
            <div className="col-md-4">
              <PageSubHeader title="Dane kontaktowe" />
              <a href="tel:+48511505050" className="contact__link">
                <img src={IcoPhone} alt="" />
                511-505-050
              </a>
              <a href="mailto:kontakt@memtech.pl" className="contact__link">
                <img src={IcoEnvelope} alt="" />
                kontakt@memtech.pl
              </a>
              <p>Godziny pracy BOK sklepu online</p>
              <p>Od poniedziałku do piątku w godzinach 10:00-18:00</p>

              <PageSubHeader
                title="Dane firmowe"
                className="page__sub-header--less-m"
              />
              <address>
                MEMTECH Sp. z o.o. Sp. k.
                <br />
                ul. Doroszewskiego 7
                <br />
                01-318 Warszawa
                <br />
                NIP: 726-265-53-49
              </address>

              <PageSubHeader
                title="Sklep stacjonarny / punkt odbioru"
                className="page__sub-header--less-m"
              />
              <address>
                ul. Beskidzka 2a
                <br />
                34-322 Rychwałd
                <br />
                woj. mazowieckie
              </address>
              <p>Godziny otwarcia sklepu</p>
              <p>Od poniedziałku do piątku w godzinach 10:00-18:00</p>
            </div>
            <div className="col-md-7 offset-md-1">
              <PageSubHeader title="Napisz do nas" />
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Contact
